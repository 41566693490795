import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/svg/BackIcon";
import { CasinoGames } from "../../../../lib/CasinoGames";
import CasinoCard from "../../../components/CasinoCard";
import SearchIcon from "../../../../assets/images/icons/magnifying-glass-search.png";

const Slots = () => {
  const categoriesTab = [
    { name: "Netent", key: "netent" },
    { name: "RedTiger", key: "redTiger" },

    // { name: "Vivo", key: "vivo", redirectUrl: "/casino/vivo" },
  ];
  const [activeCategory, setActiveCategory] = useState("netent");
  const { isAuth } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    const value = event.target.value;

    if (value) {
      setIsSearched(true);
      let filtered = [];
      Object.values(CasinoGames)
        .filter((f) => f?.key === activeCategory)
        .map((slide) => {
          Object.values(slide)
            .filter((f) => typeof f !== "string")
            .map((game) => {
              let v = Object.values(game).filter(
                (f) => f?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1
              );
              let clonedArray = [...filtered];
              clonedArray = [...filtered, ...v];
              filtered = clonedArray;
            });
        });
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setIsSearched(false);
    }
  };
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="slots-sectionPage MobileGamesSection">
          <div className="games-section">
            <div className="d-flex justify-content-between align-items-center HeadingSection mt-3 mb-3">
              <h2 className="sectionTitle border-0">Slots</h2>
              <a className="back-link" href="/">
                Back <BackIcon />
              </a>
            </div>

            <div className="SlotsTabsSection">
              <ul>
                {categoriesTab.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setFilteredData([]);
                      setIsSearched(false);
                      if (tab?.redirectUrl) {
                        navigate(tab?.redirectUrl);
                      } else {
                        setActiveCategory(tab.key);
                      }
                    }}
                    className={activeCategory === tab.key ? "active" : ""}
                  >
                    <div>{tab.name}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="SearchBar">
              <img src={SearchIcon} alt="search" className="search" />
              <input
                type="text"
                placeholder="Search Games"
                onChange={handleSearch}
              />
            </div>
            {!isSearched ? (
              <ul>
                {Array.isArray(Object.values(CasinoGames)) &&
                  Object.values(CasinoGames)
                    .filter((f) => f?.key === activeCategory)
                    .map((slide) =>
                      Object.values(slide)
                        .filter((f) => typeof f !== "string")
                        .map((game) =>
                          Object.values(game).map((item, idx) => (
                            <CasinoCard key={"casinogame" + idx} data={item} />
                          ))
                        )
                    )}
              </ul>
            ) : (
              <ul>
                {(filteredData.length &&
                  filteredData.map((game, idx) => (
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  ))) ||
                  null}
              </ul>
            )}

            {/* <ul>
              {Array.isArray(Object.values(CasinoGames?.slotsgames)) &&
                Object.values(CasinoGames?.slotsgames).map((game, idx) => {
                  return (
                    (typeof game !== "string" &&
                      Object.values(game).map((item) => {
                        return (
                          <CasinoCard key={"casinogame" + idx} data={item} />
                        );
                      })) ||
                    null
                  );
                })}
            </ul> */}
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Slots;
