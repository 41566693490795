export const gamesData = [
  {
    name: "Teen Patti 3 Cards",
    redirectUrl: "/casino/ezg-teen-patti-3-card",
    img: <img src="../../assets/images/casino/popular/TeenPatti.webp" />,
  },
  {
    name: "Andar Bahar",
    redirectUrl: "/casino/ezg-andar-bahar",
    img: <img src="../../assets/images/casino/popular/AndarBahar.webp" />,
  },
  {
    name: "Auto Roulette",
    redirectUrl: "/casino/ezg-auto-roulette",
    img: <img src="../../assets/images/casino/popular/Roulette.webp" />,
  },
  {
    name: "Dragon Tiger",
    redirectUrl: "/casino/ezg-dragon-tiger",
    img: <img src="../../assets/images/casino/popular/DragonTiger.webp" />,
  },
  {
    name: "Gonzos Quest Megaways",
    redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
    img: (
      <img src="../../assets/images/casino/popular/GonzosTreasureHunt.webp" />
    ),
  },
  {
    name: "Bet On Teen Patti",
    redirectUrl: "/casino/ezg-bet-on-teen-patti",
    img: <img src="../../assets/images/casino/popular/BetOnTeenPatti.webp" />,
  },
  {
    name: "Baccarat",
    redirectUrl: "/casino/ezg-baccarat",
    img: <img src="../../assets/images/casino/popular/Baccarat.webp" />,
  },
  {
    name: "Lucky 7",
    redirectUrl: "/casino/ezg-lucky-7",
    img: <img src="../../assets/images/casino/popular/Lucky7.webp" />,
  },
  {
    name: "Crazy Time",
    redirectUrl: "/casino/ezgevo-crazy-time",
    img: <img src="../../assets/images/casino/popular/CrazyTime.webp" />,
  },
  {
    name: "Gold Blackjack 4",
    redirectUrl: "/casino/ezg-gold-blackjack-4",
    img: <img src="../../assets/images/casino/popular/GoldBlackjack.webp" />,
  },
  {
    name: "Namaste Roulette",
    redirectUrl: "/casino/ezg-namaste-roulette",
    img: <img src="../../assets/images/casino/popular/NamasteRoulette.webp" />,
  },
  {
    name: "Ultimate Roulette",
    redirectUrl: "/casino/ezg-ultimate-roulette",
    img: <img src="../../assets/images/casino/popular/UltimateRoulette.webp" />,
  },
  {
    name: "Sic Bo",
    redirectUrl: "/casino/ezg-sic-bo",
    img: <img src="../../assets/images/casino/sidebar-games/sic-bo.webp" />,
  },
  {
    name: "Speed Roulette",
    redirectUrl: "/casino/ezg-speed-roulette",
    img: (
      <img src="../../assets/images/casino/sidebar-games/speed-roulette.webp" />
    ),
  },
  {
    name: "Rumba Blackjack 4",
    redirectUrl: "/casino/ezg-rumba-blackjack-4",
    img: <img src="../../assets/images/casino/sidebar-games/blackJack.webp" />,
  },
  {
    name: "Italian Roulette",
    redirectUrl: "/casino/ezg-italian-roulette",
    img: (
      <img src="../../assets/images/casino/sidebar-games/italian-roulette.webp" />
    ),
  },
  {
    name: "32 Cards",
    redirectUrl: "/casino/ezg-32-cards",
    img: <img src="../../assets/images/casino/sidebar-games/cards32.webp" />,
  },
  {
    name: "Aviator",
    redirectUrl: "/casino/spribe/aviator",
    img: <img src="../../assets/images/casino/VSlider/aviator.webp" />,
  },
  {
    name: "One Day Teen Patti",
    redirectUrl: "/casino/ezg-one-day-teen-patti",
    img: (
      <img src="../../assets/images/casino/VSlider/one-day-teen-patti.webp" />
    ),
  },
];
