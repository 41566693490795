import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import Blackjack1 from "../../../../assets/images/blackjack/Blackjack-1.jpg";
import Blackjack2 from "../../../../assets/images/blackjack/Blackjack-2.jpg";
import BlackjackGold4 from "../../../../assets/images/blackjack/Gold-Blackjack-4.jpg";
import BlackjackGold6 from "../../../../assets/images/blackjack/Blackjack-Gold-6.jpg";
import BlackjackPlatinum1 from "../../../../assets/images/blackjack/Blackjack-Platinum-1.jpg";
import ItalianBlackjack from "../../../../assets/images/blackjack/Italian-Blackjack.jpg";
import TurkishBlackjack from "../../../../assets/images/blackjack/Turkish-Blackjack.jpg";
import UnlimitedBlackjack from "../../../../assets/images/blackjack/Unlimited-Blackjack.jpg";
import VIPDiamondBlackjack from "../../../../assets/images/blackjack/VIP-Diamond-Blackjack.jpg";
import VIPBlackjack2 from "../../../../assets/images/blackjack/VIP-Blackjack-2.jpg";

import BlackjackA from "../../../../assets/images/blackjack/Blackjack-A.jpg";
import BlackjackB from "../../../../assets/images/blackjack/Blackjack-B.jpg";
import BlackjackC from "../../../../assets/images/blackjack/Blackjack-C.jpg";
import LightBlackjack from "../../../../assets/images/blackjack/Lightning-Blackjack.jpg";
import SalonPriveBlackjackA from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-A.jpg";
import FreebetBlackjack from "../../../../assets/images/blackjack/Freebet-Blackjack.jpg";
import BlackjackVIP1 from "../../../../assets/images/blackjack/Blackjack-VIP-1.jpg";
import Craps from "../../../../assets/images/blackjack/Craps.jpg";
import InfiniteBlackjack from "../../../../assets/images/blackjack/Infinite-Blackjack.jpg";
import SalonPriveBlackjackB from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-B.jpg";
import SalonPriveBlackjackC from "../../../../assets/images/blackjack/Salon-Privac-Blackjack-C.jpg";
import SpeedBlackjackD from "../../../../assets/images/blackjack/Speed-Blackjack-D.jpg";

const FourthGamePage = () => {
  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="container">
          <div className="game-page">
            <h2 class="sectionTitle">Blackjack</h2>
            <div className="provider-game-name">
              <h3>Ezugi</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/Blackjack1">
                    <img src={Blackjack1} />
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/BlackjackGold6">
                    <img src={BlackjackGold6} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/BlackjackPlatinum1">
                    <img src={BlackjackPlatinum1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/BlackjackGold4">
                    <img src={BlackjackGold4} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/ItalianBlackjack">
                    <img src={ItalianBlackjack} />
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/UnlimitedBlackjack">
                    <img src={UnlimitedBlackjack} />
                  </a>
                </li>

                <li>
                  <a href="/casino/ezugi/VIPDiamomdBlackjack">
                    <img src={VIPDiamondBlackjack} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name">
              <h3>Evolution</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/evolution/BlackjackA">
                    <img src={BlackjackA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackB">
                    <img src={BlackjackB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackC">
                    <img src={BlackjackC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/BlackjackVIP1">
                    <img src={BlackjackVIP1} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/Craps">
                    <img src={Craps} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/FreebetBlackjack">
                    <img src={FreebetBlackjack} />
                  </a>
                </li>

                <li>
                  <a href="/casino/evolution/InfiniteBlackjack">
                    <img src={InfiniteBlackjack} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/LightningBlackjack">
                    <img src={LightBlackjack} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackA">
                    <img src={SalonPriveBlackjackA} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackB">
                    <img src={SalonPriveBlackjackB} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SalonPriveBlackjackC">
                    <img src={SalonPriveBlackjackC} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/SpeedBlackjackD">
                    <img src={SpeedBlackjackD} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default FourthGamePage;
