import React from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import SpeedRoulette from "../../../../assets/images/roulette/Speed-Roulette.jpg";
import NamasteRoulette from "../../../../assets/images/roulette/Namaste-Roulette.jpg";
import AutoRoulette from "../../../../assets/images/roulette/Auto-Roulette.jpg";
import SpeedAutoRoulette from "../../../../assets/images/roulette/Speed-Auto-Roulette.jpg";
import DiamondRoulette from "../../../../assets/images/roulette/Diamond-Roulette.jpg";
import PrestigeRoulette from "../../../../assets/images/roulette/Prestige-Auto-Roulette.jpg";
import ItalianRoulette from "../../../../assets/images/roulette/Italian-Roulette.jpg";
import RussianRoulette from "../../../../assets/images/roulette/Russian-Roulette.jpg";
import TurkishRoulette from "../../../../assets/images/roulette/Turkish-Roulette.jpg";
import OracleRoulette from "../../../../assets/images/roulette/Oracle-360-Roulette.jpg";

import XtremeLightningRoulette from "../../../../assets/images/roulette/XXXtreme-Lightning-Roulette.jpg";
import LightningRoulette from "../../../../assets/images/roulette/Lightning-Roulette.jpg";
import InstantRoulette from "../../../../assets/images/roulette/Instant-Roulette.jpg";
import AmericanRoulette from "../../../../assets/images/roulette/American-Roulette.jpg";
import FootballStudioRoulette from "../../../../assets/images/roulette/Football-Studion-Roulette.jpg";
import Roulette1 from "../../../../assets/images/roulette/Roulette.jpg";
import DoubleBallRoulette from "../../../../assets/images/roulette/Double-ball-Roulette.jpg";
import DragonaraRoulette from "../../../../assets/images/roulette/Dragonara-Roulette.jpg";
import SalonPriveRoulette from "../../../../assets/images/roulette/Salon-Prive-Roulette.jpg";

import RouletteSupernowa from "../../../../assets/images/roulette/RouletteSupernowa.jpg";

const ThirdGamePage = () => {
  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="container">
          <div className="game-page">
            <h2 class="sectionTitle">Roulette</h2>
            <div className="provider-game-name">
              <h3>Ezugi</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="/casino/ezugi/SpeedRoulette">
                    <img src={SpeedRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/NamasteRoulette">
                    <img src={NamasteRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/AutoRoulette">
                    <img src={AutoRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/SpeedAutoRoulette">
                    <img src={SpeedAutoRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/DiamondRoulette">
                    <img src={DiamondRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/PrestigeRoulette">
                    <img src={PrestigeRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/ItalianRoulette">
                    <img src={ItalianRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/RussianRoulette">
                    <img src={RussianRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/TurkishRoulette">
                    <img src={TurkishRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/ezugi/OracleRoulette">
                    <img src={OracleRoulette} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name">
              <h3>Evolution</h3>
            </div>
            <div className="provider-game-section">
              <ul>
                <li>
                  <a href="casino/evolution/XtremeLightningRoulette">
                    <img src={LightningRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/InstantRoulette">
                    <img src={InstantRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/AmericanRoulette">
                    <img src={AmericanRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/FootballStudioRoulette">
                    <img src={FootballStudioRoulette} />
                  </a>
                </li>
                <li>
                  <a href="/casino/evolution/Roulette1">
                    <img src={Roulette1} />
                  </a>
                </li>

                <li>
                  <a href="/casino/evolution/DoubleballRoulette">
                    <img src={DoubleBallRoulette} />
                  </a>
                </li>
                {/* <li>
                  <a href="/casino/evolution/DragonaraRoulette">
                    <img src={DragonaraRoulette} />
                  </a>
                </li> */}
                <li>
                  <a href="/casino/evolution/SalonPriveRoulette">
                    <img src={SalonPriveRoulette} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="provider-game-name d-none">
              <h3>Gobet357 Games</h3>
            </div>
            <div className="provider-game-section d-none">
              <ul>
                <li>
                  <a href="/casino/ezugi/RouletteSupernowa">
                    <img src={RouletteSupernowa} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ThirdGamePage;
