export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const CasinoGames = {
  slotsgames: {
    slide1: {
      // twinspin: {
      //   redirectUrl: "/casino/ezugi/twinspin",
      //   code: "1100039",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/twin-and-spin.webp", alt: "" },
      // },
      // gonzoquest: {
      //   redirectUrl: "/casino/ezugi/gonzoquest",
      //   code: "1100018",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/gonzo-treasure-hunt.webp", alt: "" },
      // },

      // asgardianstones: {
      //   href: "/casino/ezugi/asgardianstones",
      //   code: "1100096",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/asgardianstones1.webp", alt: "" },
      // },
      // secrets: {
      //   href: "/casino/ezugi/secrets",
      //   code: "1100106",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/secrets.webp", alt: "" },
      // },
      // grandwheel: {
      //   href: "/casino/ezugi/grandwheel",
      //   code: "1100150",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/Grand-wheel.webp", alt: "" },
      // },
      // Strike777: {
      //   href: "/casino/ezugi/Strike777",
      //   code: "1100031",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Nights1000: {
      //   href: "/casino/ezugi/Nights1000",
      //   code: "1100003",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // CashUltimate: {
      //   href: "/casino/ezugi/CashUltimate",
      //   code: "1100070",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // CashVolt: {
      //   href: "/casino/ezugi/CashVolt",
      //   code: "1100071",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DazzleMegaways: {
      //   href: "/casino/ezugi/DazzleMegaways",
      //   code: "1100083",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DivinieFortuneMegaways: {
      //   href: "/casino/ezugi/DivinieFortuneMegaways",
      //   code: "1100089",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Dynamiteriches: {
      //   href: "/casino/ezugi/Dynamiteriches",
      //   code: "1100073",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FortuneHouse: {
      //   href: "/casino/ezugi/FortuneHouse",
      //   code: "1100007",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FruitShopMegaways: {
      //   href: "/casino/ezugi/FruitShopMegaways",
      //   code: "1100094",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GoldenCryptex: {
      //   href: "/casino/ezugi/GoldenCryptex",
      //   code: "1100147",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GoldenTsar: {
      //   href: "/casino/ezugi/GoldenTsar",
      //   code: "1100149",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GorillaKingdom: {
      //   href: "/casino/ezugi/GorillaKingdom",
      //   code: "1100088",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // MystryReels: {
      //   href: "/casino/ezugi/MystryReels",
      //   code: "1100061",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RageoftheSea: {
      //   href: "/casino/ezugi/RageoftheSea",
      //   code: "1100116",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ReelKingMegaways: {
      //   href: "/casino/ezugi/ReelKingMegaways",
      //   code: "1100052",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // TurnFortune: {
      //   href: "/casino/ezugi/TurnFortune",
      //   code: "1100041",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // TwinSpinMegaways: {
      //   href: "/casino/ezugi/TwinSpinMegaways",
      //   code: "1100039",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // WillysChills: {
      //   href: "/casino/ezugi/WillysChills",
      //   code: "1100128",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // VikingsSlots: {
      //   href: "/casino/ezugi/VikingsSlots",
      //   code: "1100124",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },

      // ChineseTreasures: {
      //   href: "/casino/ezugi/ChineseTreasures",
      //   code: "1100006",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DragonLuck: {
      //   href: "/casino/ezugi/DragonLuck",
      //   code: "1100001",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DragonFire: {
      //   href: "/casino/ezugi/DragonFire",
      //   code: "1100008",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DragonLuckDeluxe: {
      //   href: "/casino/ezugi/DragonLuckDeluxe",
      //   code: "1100011",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DragonLuckReel: {
      //   href: "/casino/ezugi/DragonLuckReel",
      //   code: "1100002",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // EpicJourney: {
      //   href: "/casino/ezugi/EpicJourney",
      //   code: "1100012",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GodWealth: {
      //   href: "/casino/ezugi/GodWealth",
      //   code: "1100005",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // LuckyFortune: {
      //   href: "/casino/ezugi/LuckyFortune",
      //   code: "1100010",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // MagicGate: {
      //   href: "/casino/ezugi/MagicGate",
      //   code: "1100009",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GoldenTemple: {
      //   href: "/casino/ezugi/GoldenTemple",
      //   code: "1100013",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // PhoenixPower: {
      //   href: "/casino/ezugi/PhoenixPower",
      //   code: "1100014",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // PiratesPlenty: {
      //   href: "/casino/ezugi/PiratesPlenty",
      //   code: "1100015",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GoldenOffer: {
      //   href: "/casino/ezugi/GoldenOffer",
      //   code: "1100016",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RAsLegend: {
      //   href: "/casino/ezugi/RAsLegend",
      //   code: "1100017",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GonzoMegawaya: {
      //   href: "/casino/ezugi/GonzoMegawaya",
      //   code: "1100018                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RainbowJAcket: {
      //   href: "/casino/ezugi/RainbowJAcket",
      //   code: "1100019                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // MegaPyramid: {
      //   href: "/casino/ezugi/MegaPyramid",
      //   code: "1100020                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Ozzy_Osbourne: {
      //   href: "/casino/ezugi/Ozzy_Osbourne",
      //   code: "1100024                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Jimi_Hendrix: {
      //   href: "/casino/ezugi/Jimi_Hendrix",
      //   code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Jimi_Hendrix: {
      //   href: "/casino/ezugi/Jimi_Hendrix",
      //   code: "1100026                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Jumanji: {
      //   href: "/casino/ezugi/Jumanji",
      //   code: "1100027                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // MotorheadVideoSlot: {
      //   href: "/casino/ezugi/MotorheadVideoSlot",
      //   code: "1100028                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Starburst: {
      //   href: "/casino/ezugi/Starburst",
      //   code: "1100029                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // WarofGod: {
      //   href: "/casino/ezugi/WarofGod",
      //   code: "1100030                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Christmas: {
      //   href: "/casino/ezugi/Christmas",
      //   code: "1100032                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ClusterPays: {
      //   href: "/casino/ezugi/ClusterPays",
      //   code: "1100033                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // AncientsBlessing: {
      //   href: "/casino/ezugi/AncientsBlessing",
      //   code: "1100034                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // AurumCodex: {
      //   href: "/casino/ezugi/AurumCodex",
      //   code: "1100035                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GunsNRoses: {
      //   href: "/casino/ezugi/GunsNRoses",
      //   code: "1100036                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // BorisandBoo: {
      //   href: "/casino/ezugi/BorisandBoo",
      //   code: "1100037                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // WildWest: {
      //   href: "/casino/ezugi/WildWest",
      //   code: "1100038                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // SteamTower: {
      //   href: "/casino/ezugi/SteamTower",
      //   code: "1100043                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ZeusLightning: {
      //   href: "/casino/ezugi/ZeusLightning",
      //   code: "1100044                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // WingsofRa: {
      //   href: "/casino/ezugi/WingsofRa",
      //   code: "1100045                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Trillionaire: {
      //   href: "/casino/ezugi/Trillionaire",
      //   code: "1100047                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // TreasureMine: {
      //   href: "/casino/ezugi/TreasureMine",
      //   code: "1100048                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ThorVengeance: {
      //   href: "/casino/ezugi/ThorVengeance",
      //   code: "1100049                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RegalStreak: {
      //   href: "/casino/ezugi/RegalStreak",
      //   code: "1100050                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RegalBeasts: {
      //   href: "/casino/ezugi/RegalBeasts",
      //   code: "1100051                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ReelKeeper: {
      //   href: "/casino/ezugi/ReelKeeper",
      //   code: "1100053                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RainbowJackpotsPowerLines: {
      //   href: "/casino/ezugi/RainbowJackpotsPowerLines",
      //   code: "1100055                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // PiggyRichesMegaways: {
      //   href: "/casino/ezugi/PiggyRichesMegaways",
      //   code: "1100059                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JingleBells: {
      //   href: "/casino/ezugi/JingleBells",
      //   code: "1100065                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JackinaPot: {
      //   href: "/casino/ezugi/JackinaPot",
      //   code: "1100066                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FiveStar: {
      //   href: "/casino/ezugi/FiveStar",
      //   code: "1100068                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // BountyRaid: {
      //   href: "/casino/ezugi/BountyRaid",
      //   code: "1100069                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // MysteryReels2: {
      //   href: "/casino/ezugi/MysteryReels2",
      //   code: "1100061                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DiamondBlitz: {
      //   href: "/casino/ezugi/DiamondBlitz",
      //   code: "1100072                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DragonFireMegaways: {
      //   href: "/casino/ezugi/DragonFireMegaways",
      //   code: "1100074                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Rome: {
      //   href: "/casino/ezugi/Rome",
      //   code: "1100075                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ReelRush: {
      //   href: "/casino/ezugi/ReelRush",
      //   code: "1100076                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // BloodSuckers: {
      //   href: "/casino/ezugi/BloodSuckers",
      //   code: "1100077                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Pyramid: {
      //   href: "/casino/ezugi/Pyramid",
      //   code: "1100078                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ButterflyStaxx: {
      //   href: "/casino/ezugi/ButterflyStaxx",
      //   code: "1100079                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // PiggyRiches: {
      //   href: "/casino/ezugi/PiggyRiches",
      //   code: "1100080                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DarkKing: {
      //   href: "/casino/ezugi/DarkKing",
      //   code: "1100081                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JingleSpin: {
      //   href: "/casino/ezugi/JingleSpin",
      //   code: "1100082                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Dazzle: {
      //   href: "/casino/ezugi/Dazzle",
      //   code: "1100083                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JackHammer: {
      //   href: "/casino/ezugi/JackHammer",
      //   code: "1100084                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DeadorAlive: {
      //   href: "/casino/ezugi/DeadorAlive",
      //   code: "1100085                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JackandtheBeanstalk: {
      //   href: "/casino/ezugi/JackandtheBeanstalk",
      //   code: "1100086                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DeadorAlive2: {
      //   href: "/casino/ezugi/DeadorAlive2",
      //   code: "1100087                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DivineFortuneMegaways: {
      //   href: "/casino/ezugi/DivineFortuneMegaways",
      //   code: "1100089                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GordonRamsayHellsKitchen: {
      //   href: "/casino/ezugi/GordonRamsayHellsKitchen",
      //   code: "1100090                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Elements: {
      //   href: "/casino/ezugi/Elements",
      //   code: "1100091                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // GonzosQuest: {
      //   href: "/casino/ezugi/GonzosQuest",
      //   code: "1100092                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FinnandtheSwirlySpin: {
      //   href: "/casino/ezugi/FinnandtheSwirlySpin",
      //   code: "1100093                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FruitShop: {
      //   href: "/casino/ezugi/FruitShop",
      //   code: "1100095                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // BloodSuckers2: {
      //   href: "/casino/ezugi/BloodSuckers2",
      //   code: "1100097                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ButterflyStaxx2: {
      //   href: "/casino/ezugi/ButterflyStaxx2",
      //   code: "1100098                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DazzleMeMegaways: {
      //   href: "/casino/ezugi/DazzleMeMegaways",
      //   code: "1100099                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DeadorAlive2FeatureBuy: {
      //   href: "/casino/ezugi/DeadorAlive2FeatureBuy",
      //   code: "1100100                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DoubleStacks: {
      //   href: "/casino/ezugi/DoubleStacks",
      //   code: "1100101                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FairytaleLegends: {
      //   href: "/casino/ezugi/FairytaleLegends",
      //   code: "1100102                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Flowers: {
      //   href: "/casino/ezugi/Flowers",
      //   code: "1100103                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FruitShopChristmasEdition: {
      //   href: "/casino/ezugi/FruitShopChristmasEdition",
      //   code: "1100104                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Hotline: {
      //   href: "/casino/ezugi/Hotline",
      //   code: "1100107                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // Hotline2: {
      //   href: "/casino/ezugi/Hotline2",
      //   code: "1100108                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // TheInvisibleMan: {
      //   href: "/casino/ezugi/TheInvisibleMan",
      //   code: "1100109                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // IrishPotLuck: {
      //   href: "/casino/ezugi/IrishPotLuck",
      //   code: "1100110                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JackHammer2: {
      //   href: "/casino/ezugi/JackHammer2",
      //   code: "1100111                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // FruitSpin: {
      //   href: "/casino/ezugi/FruitSpin",
      //   code: "1100112                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // JungleSpirit: {
      //   href: "/casino/ezugi/JungleSpirit",
      //   code: "1100113                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // KoiPrincess: {
      //   href: "/casino/ezugi/KoiPrincess",
      //   code: "1100114                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // DriveMultiplierMayhem: {
      //   href: "/casino/ezugi/DriveMultiplierMayhem",
      //   code: "1100115                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RageoftheSeas: {
      //   href: "/casino/ezugi/RageoftheSeas",
      //   code: "1100116                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // RichesofMidgard: {
      //   href: "/casino/ezugi/RichesofMidgard",
      //   code: "1100117                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // ScruffyDuck: {
      //   href: "/casino/ezugi/ScruffyDuck",
      //   code: "1100118                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // SecretoftheStones: {
      //   href: "/casino/ezugi/SecretoftheStones",
      //   code: "1100119                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      // SecretsofChristmas: {
      //   href: "/casino/ezugi/SecretsofChristmas",
      //   code: "1100120                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: "",
      //   cover: { src: "./images/slots/777_Strike.webp", alt: "" },
      // },
      SerengetiKings: {
        redirectUrl: "/casino/ezugi/SerengetiKings",
        code: "1100121                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Serengeti-Kings.webp",
        alt: "",
        name: "Serengeti Kings",
        providerName: "NetEnt",
      },
      SpaceWars: {
        redirectUrl: "/casino/ezugi/SpaceWars",
        code: "1100122                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Space-Wars.webp",
        alt: "",
        name: "Space Wars",
        providerName: "NetEnt",
      },
      Victorious: {
        redirectUrl: "/casino/ezugi/Victorious",
        code: "1100123                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Victorious.webp",
        alt: "",
        name: "Victorious",
        providerName: "NetEnt",
      },
      Warlords: {
        redirectUrl: "/casino/ezugi/Warlords",
        code: "1100125                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Warlords-Crystal-of-Power.webp",
        alt: "",
        name: "Warlords: Crystal of Power",
        providerName: "NetEnt",
      },
      WildWater: {
        redirectUrl: "/casino/ezugi/WildWater",
        code: "1100126                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Wild-Water.webp",
        alt: "",
        name: "Wild Water",
        providerName: "NetEnt",
      },
      WildWorlds: {
        redirectUrl: "/casino/ezugi/WildWorlds",
        code: "1100127                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Wild-Worlds.webp",
        alt: "",
        name: "Wild Worlds",
        providerName: "NetEnt",
      },
      Squad4: {
        redirectUrl: "/casino/ezugi/Squad4",
        code: "1100129                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/4-Squad.webp",
        alt: "",
        name: "4 Squad",
        providerName: "Red Tiger",
      },
      Families5: {
        redirectUrl: "/casino/ezugi/Families5",
        code: "1100130                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/5-Families.webp",
        alt: "",
        name: "5 Families",
        providerName: "Red Tiger",
      },
      AgentRoyale: {
        redirectUrl: "/casino/ezugi/AgentRoyale",
        code: "1100131                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Agent-Royale.webp",
        alt: "",
        name: "Agent Royale",
        providerName: "Red Tiger",
      },
      ArcadeBomb: {
        redirectUrl: "/casino/ezugi/ArcadeBomb",
        code: "1100132                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Arcade-Bomb.webp",
        alt: "",
        name: "Arcade Bomb",
        providerName: "Red Tiger",
      },
      Atlantis: {
        redirectUrl: "/casino/ezugi/Atlantis",
        code: "1100133                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Atlantis.webp",
        alt: "",
        name: "Atlantis",
        providerName: "Red Tiger",
      },
      AztecSpins: {
        redirectUrl: "/casino/ezugi/AztecSpins",
        code: "1100134                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Aztec-Spins.webp",
        alt: "",
        name: "Aztec Spins",
        providerName: "Red Tiger",
      },
      CinderellaBall: {
        redirectUrl: "/casino/ezugi/CinderellaBall",
        code: "1100135                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Cinderella`s-Ball.webp",
        alt: "",
        name: "Cinderella`s Ball",
        providerName: "Red Tiger",
      },
      CirquedelaFortune: {
        redirectUrl: "/casino/ezugi/CirquedelaFortune",
        code: "1100136                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Cirque-de-la-Fortune.webp",
        alt: "",
        name: "Cirque de la Fortune",
        providerName: "Red Tiger",
      },
      ClashoftheBeasts: {
        redirectUrl: "/casino/ezugi/ClashoftheBeasts",
        code: "1100137                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Clash-of-the-Beasts.webp",
        alt: "",
        name: "Clash of the Beasts",
        providerName: "Red Tiger",
      },
      CrazyGenie: {
        redirectUrl: "/casino/ezugi/CrazyGenie",
        code: "1100138                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Crazy-Genie.webp",
        alt: "",
        name: "Crazy Genie",
        providerName: "Red Tiger",
      },
      CrystalMirror: {
        redirectUrl: "/casino/ezugi/CrystalMirror",
        code: "1100139                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Crystal-Mirror.webp",
        alt: "",
        name: "Crystal Mirror",
        providerName: "Red Tiger",
      },
      DaVinciMystery: {
        redirectUrl: "/casino/ezugi/DaVinciMystery",
        code: "1100140                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Da-Vinci`s-Mystery.webp",
        alt: "",
        name: "Da Vinci`s Mystery",
        providerName: "Red Tiger",
      },
      DevilNumber: {
        redirectUrl: "/casino/ezugi/DevilNumber",
        code: "1100141                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Devil`s-Number.webp",
        alt: "",
        name: "Devil`s Number",
        providerName: "Red Tiger",
      },
      DiceDice: {
        redirectUrl: "/casino/ezugi/DiceDice",
        code: "1100142                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Dice-Dice-Dice.webp",
        alt: "",
        name: "Dice Dice Dice",
        providerName: "Red Tiger",
      },
      FiveStarsPowerReels: {
        redirectUrl: "/casino/ezugi/FiveStarsPowerReels",
        code: "1100144                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Five-Stars-Power-Reels.webp",
        alt: "",
        name: "Five Stars Power Reels",
        providerName: "Red Tiger",
      },
      GemsGoneWild: {
        redirectUrl: "/casino/ezugi/GemsGoneWild",
        code: "1100145                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Gems-Gone-Wild.webp",
        alt: "",
        name: "Gems Gone Wild",
        providerName: "Red Tiger",
      },
      GemsGoneWildPowerReels: {
        redirectUrl: "/casino/ezugi/GemsGoneWildPowerReels",
        code: "1100146                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Gems-Gone-Wild-Power-Reels.webp",
        alt: "",

        name: "Gems Gone Wild Power Reels",
        providerName: "Red Tiger",
      },
      GoldenLeprechaunMegaways: {
        redirectUrl: "/casino/ezugi/GoldenLeprechaunMegaways",
        code: "1100148                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Golden-Leprechaun-Megaways.webp",
        alt: "",

        name: "Golden Leprechaun Megaways",
        providerName: "Red Tiger",
      },
      HoardofPoseidon: {
        redirectUrl: "/casino/ezugi/HoardofPoseidon",
        code: "1100151                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Hoard-of-Poseidon.webp",
        alt: "",
        name: "Hoard of Poseidon",
        providerName: "Red Tiger",
      },
      JewelScarabs: {
        redirectUrl: "/casino/ezugi/JewelScarabs",
        code: "1100152                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Jewel-Scarabs.webp",
        alt: "",
        name: "Jewel Scarabs",
        providerName: "Red Tiger",
      },
      JingleBellsPowerReels: {
        redirectUrl: "/casino/ezugi/JingleBellsPowerReels",
        code: "1100153                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Jingle-Bells-Power-Reels.webp",
        alt: "",
        name: "Jingle Bells Power Reels",
        providerName: "Red Tiger",
      },
      LegendofAthena: {
        redirectUrl: "/casino/ezugi/LegendofAthena",
        code: "1100154                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Legend-of-Athena.webp",
        alt: "",
        name: "Legend of Athena",
        providerName: "Red Tiger",
      },
      LuckyHalloween: {
        redirectUrl: "/casino/ezugi/LuckyHalloween",
        code: "1100155                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Lucky-Halloween.webp",
        alt: "",
        name: "Lucky Halloween",
        providerName: "Red Tiger",
      },
      LuckyLittleDevil: {
        redirectUrl: "/casino/ezugi/LuckyLittleDevil",
        code: "1100156                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Lucky-Little-Devil.webp",
        alt: "",
        name: "Lucky Little Devil",
        providerName: "Red Tiger",
      },
      LuckyValentine: {
        redirectUrl: "/casino/ezugi/LuckyValentine",
        code: "1100157                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Lucky-Valentine.webp",
        alt: "",
        name: "Lucky Valentine",
        providerName: "Red Tiger",
      },
      LuckyWizard: {
        redirectUrl: "/casino/ezugi/LuckyWizard",
        code: "1100158                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Lucky-Wizard.webp",
        alt: "",
        name: "Lucky Wizard",
        providerName: "Red Tiger",
      },
      Masquerade: {
        redirectUrl: "/casino/ezugi/Masquerade",
        code: "1100159                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Masquerade.webp",
        alt: "",
        name: "Masquerade",
        providerName: "Red Tiger",
      },
      MayanGods: {
        redirectUrl: "/casino/ezugi/MayanGods",
        code: "1100160                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Mayan-Gods.webp",
        alt: "",
        name: "Mayan Gods",
        providerName: "Red Tiger",
      },
      MegaDragon: {
        redirectUrl: "/casino/ezugi/MegaDragon",
        code: "1100161                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Mega-Dragon.webp",
        alt: "",
        name: "Mega Dragon",
        providerName: "Red Tiger",
      },
      MegaJade: {
        redirectUrl: "/casino/ezugi/MegaJade",
        code: "1100162                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Mega-Jade.webp",
        alt: "",
        name: "Mega Jade",
        providerName: "Red Tiger",
      },
      MegaRise: {
        redirectUrl: "/casino/ezugi/MegaRise",
        code: "1100163                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Mega-Rise.webp",
        alt: "",
        name: "Mega Rise",
        providerName: "Red Tiger",
      },
      MultiplierRiches: {
        redirectUrl: "/casino/ezugi/MultiplierRiches",
        code: "1100164                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Multiplier-Riches.webp",
        alt: "",
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      MysteryReelsMegaways: {
        redirectUrl: "/casino/ezugi/MysteryReelsMegaways",
        code: "1100165                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Mystery_Reels_Megaways.webp",
        alt: "",
        name: "Mystery Reels Megaways",
        providerName: "Red Tiger",
      },
      NinjaWays: {
        redirectUrl: "/casino/ezugi/NinjaWays",
        code: "1100166                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Ninja-Ways.webp",
        alt: "",
        name: "Ninja Ways",
        providerName: "Red Tiger",
      },
      PathofDestiny: {
        redirectUrl: "/casino/ezugi/PathofDestiny",
        code: "1100167                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Path-of-Destiny.webp",
        alt: "",
        name: "Path of Destiny",
        providerName: "Red Tiger",
      },
      PersianFortune: {
        redirectUrl: "/casino/ezugi/PersianFortune",
        code: "1100168                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Persian-Fortune.webp",
        alt: "",
        name: "Persian Fortune",
        providerName: "Red Tiger",
      },
      ReelHeist: {
        redirectUrl: "/casino/ezugi/ReelHeist",
        code: "1100169                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Reel-Heist.webp",
        alt: "",
        name: "Reel Heist",
        providerName: "Red Tiger",
      },
      RobinHoodWildForest: {
        redirectUrl: "/casino/ezugi/RobinHoodWildForest",
        code: "1100170                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.webp",
        alt: "",
        name: "Robin Hood`s Wild Forest",
        providerName: "Red Tiger",
      },
      SnowWild: {
        redirectUrl: "/casino/ezugi/SnowWild",
        code: "1100171                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.webp",
        alt: "",

        name: "Snow Wild And The 7 Features",
        providerName: "Red Tiger",
      },
      SylvanSpirits: {
        redirectUrl: "/casino/ezugi/SylvanSpirits",
        code: "1100172                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Sylvan-Spirits.webp",
        alt: "",
        name: "Sylvan Spirits",
        providerName: "Red Tiger",
      },
      TheGreatestTrainRobbery: {
        redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
        code: "1100173                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/The-Greatest-Train-Robbery.webp",
        alt: "",

        name: "The Greatest Train Robbery",
        providerName: "Red Tiger",
      },
      TheWildHatter: {
        redirectUrl: "/casino/ezugi/TheWildHatter",
        code: "1100174                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/The-Wild-Hatter.webp",
        alt: "",
        name: "The Wild Hatter",
        providerName: "Red Tiger",
      },
      ThorLightning: {
        redirectUrl: "/casino/ezugi/ThorLightning",
        code: "1100175                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Thor`s-Lightning.webp",
        alt: "",
        name: "Thor`s Lightning",
        providerName: "Red Tiger",
      },
      TikiFruits: {
        redirectUrl: "/casino/ezugi/TikiFruits",
        code: "1100176                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Tiki-Fruits.webp",
        alt: "",
        name: "Tiki Fruits",
        providerName: "Red Tiger",
      },
      TotemLightning: {
        redirectUrl: "/casino/ezugi/TotemLightning",
        code: "1100177                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Totem-Lightning.webp",
        alt: "",
        name: "Totem Lightning",
        providerName: "Red Tiger",
      },
      VaultCracker: {
        redirectUrl: "/casino/ezugi/VaultCracker",
        code: "1100178                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Vault-Cracker.webp",
        alt: "",
        name: "Vault Cracker",
        providerName: "Red Tiger",
      },
      VaultofAnubis: {
        redirectUrl: "/casino/ezugi/VaultofAnubis",
        code: "1100179                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Vault-of-Anubis.webp",
        alt: "",
        name: "Vault of Anubis",
        providerName: "Red Tiger",
      },
      WellofWishes: {
        redirectUrl: "/casino/ezugi/WellofWishes",
        code: "1100180                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Well-of-Wishes.webp",
        alt: "",
        name: "Well of Wishes",
        providerName: "Red Tiger",
      },
      WildCatsMultiline: {
        redirectUrl: "/casino/ezugi/WildCatsMultiline",
        code: "1100181                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Wild-Cats-Multiline.webp",
        alt: "",
        name: "Wild Cats Multiline",
        providerName: "Red Tiger",
      },
      WildOClock: {
        redirectUrl: "/casino/ezugi/WildOClock",
        code: "1100182                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Wild-O`Clock.webp",
        alt: "",
        name: "Wild O`Clock",
        providerName: "Red Tiger",
      },
      WildChest: {
        redirectUrl: "/casino/ezugi/WildChest",
        code: "1100183                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Wild-Wild-Chest.webp",
        alt: "",
        name: "Wild Wild Chest",
        providerName: "Red Tiger",
      },
      WinEscalator: {
        redirectUrl: "/casino/ezugi/WinEscalator",
        code: "1100184                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Win-Escalator.webp",
        alt: "",
        name: "Win Escalator",
        providerName: "Red Tiger",
      },
      YucatanMystery: {
        redirectUrl: "/casino/ezugi/YucatanMystery",
        code: "1100185                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        imgUrl: "./images/slots/Yucatan`s-Mystery.webp",
        alt: "",
        name: "Yucatan Mystery",
        providerName: "Red Tiger",
      },
    },

    name: "Slots",
  },

  netenttab: {
    slide1: {
      asgardianstones: {
        redirectUrl: "/casino/ezgne-asgardian-stones",
        code: "1100096",
        casino: "ezugi",
        imgUrl: "./images/slots/asgardianstones.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Asgardian Stones",
        providerName: "NetEnt",
      },
      secrets: {
        redirectUrl: "/casino/ezgne-secrets-of-atlantis",
        code: "1100106",
        casino: "ezugi",
        imgUrl: "./images/slots/secrets-of-atlantis.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Secrets of Atlantis",
        providerName: "NetEnt",
      },
      SteamTower: {
        redirectUrl: "/casino/ezgne-steam-tower",
        code: "1100043",
        casino: "ezugi",
        imgUrl: "./images/slots/Steam-Tower.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Steam Tower",
        providerName: "NetEnt",
      },
      DivinieFortuneMegaways: {
        redirectUrl: "/casino/ezgne-divine-fortune-megaways",
        code: "1100089",
        casino: "ezugi",
        imgUrl: "./images/slots/divine_fortune_megaways.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Divine Fortune Megaways",
        providerName: "NetEnt",
      },
      FruitShopMegaways: {
        redirectUrl: "/casino/ezgne-fruit-shop-megaways",
        code: "1100094",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit_Shop_Megaways.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop Megaways",
        providerName: "NetEnt",
      },
      GorillaKingdom: {
        redirectUrl: "/casino/ezgne-gorilla-kingdom",
        code: "1100088",
        casino: "ezugi",
        imgUrl: "./images/slots/Gorilla_Kingdom.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gorilla Kingdom",
        providerName: "NetEnt",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezgrt-reel-king-mega",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel_King_Mega.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
      },
      Christmas: {
        redirectUrl: "/casino/ezgne-aloha-christmas",
        code: "1100032",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Christmas.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha! Christmas",
        providerName: "NetEnt",
      },
      ClusterPays: {
        redirectUrl: "/casino/ezgne-aloha-cluster-pays",
        code: "1100033",
        casino: "ezugi",
        imgUrl: "./images/slots/Aloha-Cluster-Pays.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha! Cluster Pays",
        providerName: "NetEnt",
      },
      WildWest: {
        redirectUrl: "/casino/ezgne-wild-wild-west-the-great-train-heist",
        code: "1100038",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-West-The-Great-Train-Heist.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Wild West: The Great Train Heist",
        providerName: "NetEnt",
      },
      TwinSpinMegaways: {
        redirectUrl: "/casino/ezgne-twin-spin-megaways",
        code: "1100039",
        casino: "ezugi",
        imgUrl: "./images/slots/Twin_Spin_Megaways.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Twin Spin Megaways",
        providerName: "NetEnt",
      },
      TurnFortune: {
        redirectUrl: "/casino/ezgne-turn-your-fortune",
        code: "1100041",
        casino: "ezugi",
        imgUrl: "./images/slots/Turn_Your_Fortune.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Turn Your Fortune",
        providerName: "NetEnt",
      },
    },
    slide2: {
      Pyramid: {
        redirectUrl: "/casino/ezgne-pyramid-quest-for-immortality",
        code: "1100078",
        casino: "ezugi",
        imgUrl: "./images/slots/Pyramid-Quest-for-Immortality.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Pyramid: Quest for Immortality",
        providerName: "NetEnt",
      },
      PiggyRiches: {
        redirectUrl: "/casino/ezgne-piggy-riches",
        code: "1100080",
        casino: "ezugi",
        imgUrl: "./images/slots/Piggy-Riches.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Piggy Riches",
      },
      DarkKing: {
        redirectUrl: "/casino/ezgne-dark-king-forbidden-riches",
        code: "1100081",
        casino: "ezugi",
        imgUrl: "./images/slots/Dark-King-Forbidden-Riches.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dark King: Forbidden Riches",
        providerName: "NetEnt",
      },
      JingleSpin: {
        redirectUrl: "/casino/ezgne-jingle-spin",
        code: "1100082",
        casino: "ezugi",
        imgUrl: "./images/slots/Jingle-Spin.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jingle Spin",
        providerName: "NetEnt",
      },
      JackHammer: {
        redirectUrl: "/casino/ezgne-jack-hammer",
        code: "1100084",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-Hammer.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack Hammer",
        providerName: "NetEnt",
      },
      DeadorAlive: {
        redirectUrl: "/casino/ezgne-dead-or-alive",
        code: "1100085",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive",
        providerName: "NetEnt",
      },
      DeadorAlive2: {
        redirectUrl: "/casino/ezgne-dead-or-alive-2",
        code: "1100087",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2",
        providerName: "NetEnt",
      },
      JackandtheBeanstalk: {
        redirectUrl: "/casino/ezgne-jack-and-the-beanstalk",
        code: "1100086",
        casino: "ezugi",
        imgUrl: "./images/slots/Jack-and-the-Beanstalk.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Jack and the Beanstalk",
        providerName: "NetEnt",
      },
      Elements: {
        redirectUrl: "/casino/ezgne-elements-the-awakening",
        code: "1100091",
        casino: "ezugi",
        imgUrl: "./images/slots/Elements-The-Awakening.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Elements: The Awakening",
        providerName: "NetEnt",
      },
      FinnandtheSwirlySpin: {
        redirectUrl: "/casino/ezgne-finn-and-the-swirly-spin",
        code: "1100093",
        casino: "ezugi",
        imgUrl: "./images/slots/Finn-and-the-Swirly-Spin.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Finn and the Swirly Spin",
        providerName: "NetEnt",
      },
      FruitShop: {
        redirectUrl: "/casino/ezgne-fruit-shop",
        code: "1100095",
        casino: "ezugi",
        imgUrl: "./images/slots/Fruit-Shop.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop",
        providerName: "NetEnt",
      },
      DeadorAlive2FeatureBuy: {
        redirectUrl: "/casino/ezugi/DeadorAlive2FeatureBuy",
        code: "1100100",
        casino: "ezugi",
        imgUrl: "./images/slots/Dead-or-Alive-2-Feature-Buy.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dead or Alive 2 Feature Buy",
        providerName: "NetEnt",
      },
    },

    name: "Netent",
    key: "netent",
  },

  redtigertab: {
    slide1: {
      NinjaWays: {
        redirectUrl: "/casino/ezgrt-ninja-ways",
        code: "1100166",
        casino: "ezugi",
        imgUrl: "./images/slots/Ninja-Ways.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Ninja Ways",
        providerName: "Red Tiger",
      },
      PathofDestiny: {
        redirectUrl: "/casino/ezgrt-path-of-destiny",
        code: "1100167",
        casino: "ezugi",
        imgUrl: "./images/slots/Path-of-Destiny.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Path of Destiny",
        providerName: "Red Tiger",
      },
      PersianFortune: {
        redirectUrl: "/casino/ezgrt-persian-fortune",
        code: "1100168",
        casino: "ezugi",
        imgUrl: "./images/slots/Persian-Fortune.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Persian Fortune",
        providerName: "Red Tiger",
      },
      ReelHeist: {
        redirectUrl: "/casino/ezgrt-reel-heist",
        code: "1100169",
        casino: "ezugi",
        imgUrl: "./images/slots/Reel-Heist.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel Heist",
        providerName: "Red Tiger",
      },
      RobinHoodWildForest: {
        redirectUrl: "/casino/ezgrt-robin-hoods-wild-forest",
        code: "1100170",
        casino: "ezugi",
        imgUrl: "./images/slots/Robin-Hood`s-Wild-Forest.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Robin Hood`s Wild Forest",
        providerName: "Red Tiger",
      },
      SnowWild: {
        redirectUrl: "/casino/ezgrt-snow-wild-and-the-7-features",
        code: "1100171",
        casino: "ezugi",
        imgUrl: "./images/slots/Snow-Wild-And-The-7-Features.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Snow Wild And The 7 Features",
        providerName: "Red Tiger",
      },
      SylvanSpirits: {
        redirectUrl: "/casino/ezgrt-sylvan-spirits",
        code: "1100172",
        casino: "ezugi",
        imgUrl: "./images/slots/Sylvan-Spirits.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Sylvan Spirits",
        providerName: "Red Tiger",
      },
      TheGreatestTrainRobbery: {
        redirectUrl: "/casino/ezgrt-the-greatest-train-robbery",
        code: "1100173",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Greatest-Train-Robbery.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Greatest Train Robbery",
        providerName: "Red Tiger",
      },
      TheWildHatter: {
        redirectUrl: "/casino/ezgrt-the-wild-hatter",
        code: "1100174",
        casino: "ezugi",
        imgUrl: "./images/slots/The-Wild-Hatter.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Wild Hatter",
        providerName: "Red Tiger",
      },
      ThorLightning: {
        redirectUrl: "/casino/ezgrt-thors-lightning",
        code: "1100175",
        casino: "ezugi",
        imgUrl: "./images/slots/Thor`s-Lightning.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Thor`s Lightning",
        providerName: "Red Tiger",
      },
      DragonLuck: {
        redirectUrl: "/casino/ezgrt-dragons-luck",
        code: "1100001",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck",
        providerName: "Red Tiger",
      },
      DragonLuckReel: {
        redirectUrl: "/casino/ezgrt-dragons-luck-power-reels",
        code: "1100002",
        casino: "ezugi",
        imgUrl: "./images/slots/Dragon's-Luck-Power-Reels.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Power Reels",
        providerName: "Red Tiger",
      },
    },
    slide2: {
      TikiFruits: {
        redirectUrl: "/casino/ezgrt-tiki-fruits",
        code: "1100176",
        casino: "ezugi",
        imgUrl: "./images/slots/Tiki-Fruits.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Tiki Fruits",
        providerName: "Red Tiger",
      },
      TotemLightning: {
        redirectUrl: "/casino/ezgrt-totem-lightning",
        code: "1100177",
        casino: "ezugi",
        imgUrl: "./images/slots/Totem-Lightning.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Totem Lightning",
        providerName: "Red Tiger",
      },
      VaultCracker: {
        redirectUrl: "/casino/ezgrt-vault-cracker",
        code: "1100178",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-Cracker.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault Cracker",
        providerName: "Red Tiger",
      },
      VaultofAnubis: {
        redirectUrl: "/casino/ezgrt-vault-of-anubis",
        code: "1100179",
        casino: "ezugi",
        imgUrl: "./images/slots/Vault-of-Anubis.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault of Anubis",
        providerName: "Red Tiger",
      },
      WellofWishes: {
        redirectUrl: "/casino/ezgrt-well-of-wishes",
        code: "1100180",
        casino: "ezugi",
        imgUrl: "./images/slots/Well-of-Wishes.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Well of Wishes",
        providerName: "Red Tiger",
      },
      WildCatsMultiline: {
        redirectUrl: "/casino/ezgrt-wild-cats-multiline",
        code: "1100181",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Cats-Multiline.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Cats Multiline",
        providerName: "Red Tiger",
      },
      WildOClock: {
        redirectUrl: "/casino/ezgrt-wild-oclock",
        code: "1100182",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-O`Clock.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild O`Clock",
        providerName: "Red Tiger",
      },
      WildChest: {
        redirectUrl: "/casino/ezgrt-wild-wild-chest",
        code: "1100183",
        casino: "ezugi",
        imgUrl: "./images/slots/Wild-Wild-Chest.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Wild Chest",
        providerName: "Red Tiger",
      },
      WinEscalator: {
        redirectUrl: "/casino/ezgrt-win-escalator",
        code: "1100184",
        casino: "ezugi",
        imgUrl: "./images/slots/Win-Escalator.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Win Escalator",
        providerName: "Red Tiger",
      },
      YucatanMystery: {
        redirectUrl: "/casino/ezgrt-yucatans-mystery",
        code: "1100185",
        casino: "ezugi",
        imgUrl: "./images/slots/Yucatan`s-Mystery.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Yucatan`s Mystery",
        providerName: "Red Tiger",
      },
      DragonFire: {
        redirectUrl: "/casino/ezgrt-dragons-fire",
        code: "1100008",
        casino: "ezugi",
        imgUrl: "./images/slots/DragonFire.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Fire",
        providerName: "Red Tiger",
      },
      DragonLuckDeluxe: {
        redirectUrl: "/casino/ezgrt-dragons-luck-deluxe",
        code: "1100011",
        casino: "ezugi",
        imgUrl: "./images/slots/DragonsluckDeluxe.webp",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Deluxe",
        providerName: "Red Tiger",
      },
    },
    name: "Red Tiger",
    key: "redTiger",
  },

  providers: {
    slide1: {
      evolution: {
        redirectUrl: "/casino/evolution",
        code: "                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         ",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        cover: { src: "./images/provider/Evo.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Evolution.jpg",
        alt: "",
        name: "Evolution",
        providerName: "Evolution",
      },
      ezugi: {
        redirectUrl: "/casino/ezugi",
        code: "",
        casino: "ezugi",
        provider: "",
        homeUrl: "",
        cover: { src: "./images/provider/Ezugi.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Ezugi.jpg",
        alt: "",
        name: "Ezugi",
        providerName: "Ezugi",
      },
      supernowa: {
        redirectUrl: "/casino/supernowa",
        code: "",
        casino: "wco",
        provider: "SN",
        homeUrl: "",
        cover: { src: "./images/provider/Supernowa.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Supernowa.jpg",
        alt: "",
        games: {},
        name: "Supernowa",
        providerName: "Supernowa",
      },
      worldcasino: {
        redirectUrl: "/casino/worldcasino",
        code: "",
        casino: "wco",
        provider: "",
        homeUrl: "",
        cover: { src: "./images/provider/WorldCasino.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/WorldCasino.jpg",
        alt: "",
        games: {},
        name: "World Casino",
        providerName: "World Casino",
      },
      xpg: {
        redirectUrl: "/casino/xpg",
        code: "",
        casino: "wco",
        provider: "XPG",
        homeUrl: "",
        cover: { src: "./images/provider/Xpg.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/XPG.jpg",
        alt: "",
        games: {},
        name: "XPG",
        providerName: "XPG",
      },
      // onetouch: {
      //   redirectUrl: "/casino/onetouch",
      //   code: "",
      //   casino: "wco",
      //   provider: "OT",
      //   homeUrl: "",
      //   cover: { src: "./images/provider/OneTouch.webp", alt: "" },
      //   imgUrl: "./images/provider/slotspage/OneTouch.jpg",
      //   alt: "",
      //   games: {},
      //   name: "One Touch",
      //   providerName: "One Touch",
      // },
      onlyplay: {
        redirectUrl: "/casino/onlyplay",
        code: "",
        casino: "wco",
        provider: "GT",
        homeUrl: "",
        cover: { src: "./images/provider/OnlyPlay.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/OnlyPlay.jpg",
        alt: "",
        games: {},
        name: "Only Play",
        providerName: "Only Play",
      },
      pragmaticplay: {
        redirectUrl: "/casino/pragmaticplay",
        code: "",
        casino: "wco",
        provider: "PP",
        homeUrl: "",
        cover: { src: "./images/provider/Pragamatic.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Pragamatic.jpg",
        alt: "",
        games: {},
        name: "Pragmatic Play",
        providerName: "Pragmatic Play",
      },
      qtech: {
        redirectUrl: "/casino/qtech",
        code: "",
        casino: "wco",
        provider: "QT",
        homeUrl: "",
        cover: { src: "./images/provider/Qtech.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Qtech.jpg",
        alt: "",
        games: {},
        name: "Qtech",
        providerName: "Qtech",
      },
      vivo: {
        redirectUrl: "/casino/vivo",
        code: "vivo",
        casino: "vivo",
        provider: "",
        homeUrl: "",
        cover: { src: "./images/provider/Vivo.webp", alt: "" },
        imgUrl: "./images/provider/slotspage/Vivo.jpg",
        name: "Vivo Gaming",
        providerName: "Vivo",
      },
    },

    name: "Providers",
  },
};
