export const casinoTabs = {
  roulettetab: {
    RouletteVip: {
      href: "/casino/evolution/RouletteVip",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino02.png", alt: "" },
    },

    RouletteLive: {
      href: "/casino/evolution/RouletteLive",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino03.png", alt: "" },
    },
    AmericanRoulette: {
      href: "/casino/evolution/AmericanRoulette",
      code: "1000011",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino04.png", alt: "" },
    },
    AmericanRouletteFirstPerson: {
      href: "/casino/evolution/AmericanRouletteFirstPerson",
      code: "1000312",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino08.png", alt: "" },
    },
    LightningRouletteFirstPerson: {
      href: "/casino/evolution/LightningRouletteFirstPerson",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino05.png", alt: "" },
    },
    LightingsRoulette: {
      href: "/casino/evolution/LightingRoulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino015.png", alt: "" },
    },
    AutoRouletteLive: {
      href: "/casino/evolution/doubleballroulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    LightingRoulette: {
      href: "/casino/evolution/LightingRoulette",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/casino01111.png",
        alt: "",
      },
    },
    namasteroulette: {
      href: "/casino/ezugi/namasteroulette",
      code: 221005,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casinio28.png", alt: "" },
    },

    AutoRouletteLive: {
      href: "/casino/evolution/DoubleBallRouletteLive",
      code: "1000104",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino070.PNG", alt: "" },
    },

    PortmasoRoulette: {
      href: "/casino/evolution/PortmasoRoulette",
      code: 611004,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino013.png", alt: "" },
    },
    marinaroulette: {
      href: "/casino/ezugi/marinaroulette",
      code: 321000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino019.png", alt: "" },
    },

    OracleRoulette360: {
      href: "/casino/ezugi/OracleRoulette360",
      code: "611003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino030.png", alt: "" },
    },
    diamondroulette: {
      href: "/casino/ezugi/diamondroulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino028.png", alt: "" },
    },
    speedroulette: {
      href: "/casino/ezugi/speedroulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino029.PNG", alt: "" },
    },

    oracleRoulette: {
      href: "/casino/ezugi/oracleRoulette",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino010.png", alt: "" },
    },
    AutoRoulette1: {
      href: "/casino/ezugi/AutoRoulette1",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino026.png", alt: "" },
    },
    cumbiaRoulette: {
      href: "/casino/ezugi/cumbiaRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/roulette/casino031.png",
        alt: "",
        className: "size_images",
      },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino014.png", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino012.png", alt: "" },
    },

    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino011.png", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/ezugi/autoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino032.PNG", alt: "" },
    },

    TurkishsRoulettes: {
      href: "/casino/evolution/TurkishsRoulettes",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino017.png", alt: "" },
    },

    Roulettelive: {
      href: "/casino/evolution/Roulettelive",
      code: "1",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino033.png", alt: "" },
    },
    autORoulette: {
      href: "/casino/ezugi/autORoulette",
      code: "1",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino035.png", alt: "" },
    },
    Dragonroulette: {
      href: "/casino/xpg/Dragonroulette",
      code: "",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/roulette/casino023.png", alt: "" },
    },
    LiveSpeedRoulette: {
      href: "/casino/ezugi/LiveSpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino032.PNG", alt: "" },
    },

    LightingLiveRoulette: {
      href: "/casino/evolution/LightingLiveRoulette",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino022.png", alt: "" },
    },
    Roulette: {
      href: "/casino/evolution/Roulette",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino078.PNG", alt: "" },
    },

    americanRouletLive: {
      href: "/casino/evolution/americanRouletLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino076.PNG", alt: "" },
    },
    autoRouletteLive: {
      href: "/casino/evolution/autoRouletteLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino077.PNG", alt: "" },
    },
    AutoFastRouletLive: {
      href: "/casino/evolution/AutoFastRouletLive",
      code: "RT",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/roulette/casino075.PNG", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/evolution/Baccaratfirstperson",
      code: "1000145",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino028.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino029.png", alt: "" },
    },
    baccaratLive: {
      href: "/casino/evolution/baccaratLive",
      code: "1000012",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino0011.PNG", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },

    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },

    KnockOutbaccarat: {
      href: "/casino/ezugi/KnockOutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino029.png", alt: "" },
    },
    noCommisionBaccarat: {
      href: "/casino/ezugi/noCommisionBaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino027.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/evolution/Baccarat",
      code: "BAC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino080.PNG", alt: "" },
    },
    Baccaratpro: {
      href: "/casino/evolution/Baccaratpro",
      code: "1000145",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino040.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/evolution/Nocommissiobaccarat",
      code: "10",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino041.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/evolution/Knockoutbaccarat",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino042.PNG", alt: "" },
    },
    baccaratlive: {
      href: "/casino/ezugi/baccaratlive",
      code: "10",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino028.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casino031.png", alt: "" },
    },
    // Baccaratpro02: {
    //   href: "/casino/ezugi/Baccaratpro02",
    //   code: "101",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino028.png", alt: "" },
    // },
    // SpeedBaccarat: {
    //   href: "/casino/ezugi/SpeedBaccarat",
    //   code: "105",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/casino029.png", alt: "" },
    // },
    // Marina03baccarat: {
    //   href: "/casino/ezugi/Marina03baccarat",
    //   code: "32103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    // },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino032.png", alt: "" },
    },
    AndarBaharlive: {
      href: "/casino/ezugi/AndarBaharlive",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino030.png", alt: "" },
    },
    AndarBahar: {
      href: "/casino/ezugi/AndarBahar",
      code: "ABC",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino091.PNG", alt: "" },
    },
    GoaAndarBahar: {
      href: "/casino/ezugi/GoaAndarBahar",
      code: "AB2",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino090.PNG", alt: "" },
    },
    Andarbahar: {
      href: "/casino/evolution/Andarbahar",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0112.png", alt: "" },
    },
    Andarbaharlive: {
      href: "/casino/evolution/Andarbaharlive",
      code: "",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0113.png", alt: "" },
    },
    AndarbaharLive: {
      href: "/casino/xpg/AndarbaharLive",
      code: "328000",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0115.png", alt: "" },
    },
    AndarBaharlivee: {
      href: "/casino/xpg/AndarBaharlivee",
      code: "328000",
      casino: "wco",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/casino0114.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino033.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino061.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/xpg/TeenPatti03",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino034.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/xpg/TeenPatti04",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino031.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/xpg/TeenPatti05",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino035.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/xpg/TeenPatti06",
      code: "227103",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/poker/casino032.png", alt: "" },
    },
    Poker: {
      href: "/casino/ezugi/Poker",
      code: "PK",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino093.PNG", alt: "" },
    },
    ThreeTeenPatti: {
      href: "/casino/ezugi/ThreeTeenPatti",
      code: "V3TP",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino094.PNG", alt: "" },
    },
    TeenPatti09: {
      href: "/casino/ezugi/TeenPatti09",
      code: "TP",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/poker/casino092.PNG", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/evolution/Blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino060.png", alt: "" },
    },

    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino040.png", alt: "" },
    },
    vipBlackjack: {
      href: "/casino/ezugi/vipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino039.png", alt: "" },
    },
    italianBlackjack: {
      href: "/casino/ezugi/italianBlackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino053.png", alt: "" },
    },
    unlimitedblackjack: {
      href: "/casino/ezugi/unlimitedblackjack",
      code: "51",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino043.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino044.png", alt: "" },
    },
    rumbablackjack: {
      href: "/casino/ezugi/rumbablackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino050.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino046.png", alt: "" },
    },
    goldBlackjack: {
      href: "/casino/xpg/goldBlackjack",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino042.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/Blackjacklive",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino099.PNG", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/evolution/Blackjack",
      code: "1000048",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino060.png", alt: "" },
    },
    Blackjacklivee: {
      href: "/casino/evolution/Blackjacklivee",
      code: "1000032",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0222.png", alt: "" },
    },
    BlackJacklivee: {
      href: "/casino/evolution/BlackJacklivee",
      code: "1000031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0223.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/supernowa/BlackjackLive",
      code: "422",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino099.PNG", alt: "" },
    },
    GoldBlackjackLive: {
      href: "/casino/ezugi/GoldBlackjackLive",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino042.png", alt: "" },
    },
    PlaastinumBlackjacksss: {
      href: "/casino/ezugi/PlaastinumBlackjacksss",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0224.png", alt: "" },
    },
    ItasasdlianBlackjackaasas: {
      href: "/casino/ezugi/ItasasdlianBlackjackaasas",
      code: "11",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/blackjack/casino0225.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/casino057.png", alt: "" },
    },
    thirtytwoCardss: {
      href: "/casino/ezugi/thirtytwoCardss",
      code: "C32",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/card/casino0100.PNG", alt: "" },
    },
  },
  sevenLuckytab: {
    luckyseven: {
      href: "/casino/ezugi/luckyseven",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino056.png", alt: "" },
    },
    Lucky: {
      href: "/casino/supernowa/Lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/lucky7/casino100.PNG", alt: "" },
    },
  },
  sicbotab: {
    supersicbo: {
      href: "/casino/evolution/supersicbo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino060.png", alt: "" },
    },
    craps: {
      href: "/casino/evolution/craps",
      code: "1000248",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino0132.png", alt: "" },
    },
    lightningdice: {
      href: "/casino/evolution/lightningdice",
      code: "1000076",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino062.png", alt: "" },
    },
    ultimatesicbo: {
      href: "/casino/ezugi/ultimatesicbo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/casino058.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/xpg/sicbodice04",
      code: "38",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/dice/casino059.png", alt: "" },
    },
    sicbo: {
      href: "/casino/supernowa/sicbo",
      code: "38",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      cover: { src: "./images/dice/casino101.PNG", alt: "" },
    },
  },
  BetOnNumberTab: {
    BetOnNumberHd: {
      href: "/casino/ezugi/BetOnNumberHd",
      code: "602000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino035.png", alt: "" },
    },
    CrazyTime: {
      href: "/casino/evolution/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino039.png", alt: "" },
    },
    DreamCatcher: {
      href: "/casino/evolution/DreamCatcher",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0227.png", alt: "" },
    },
    MonopolyLive: {
      href: "/casino/evolution/MonopolyLive",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/games/casino0229.png", alt: "" },
    },
    WheelOfFortune: {
      href: "/casino/xpg/WheelOfFortune",
      code: "224100",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      cover: { src: "./images/games/casino0228.png", alt: "" },
    },
  },
  indianGames: {
    IndianNamasteRoulette: {
      href: "/casino/ezugi/IndianNamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/01.jpg", alt: "" },
    },
    IndianTeenPatti: {
      href: "/casino/ezugi/IndianTeenPatti",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/02.jpg", alt: "" },
    },

    onedayteenpatti: {
      href: "/casino/ezugi/onedayteenpatti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/03.jpg", alt: "" },
    },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
    beton: {
      href: "/casino/ezugi/beton",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/05.jpg", alt: "" },
    },
    oneday: {
      href: "/casino/ezugi/oneday",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/06.jpg", alt: "" },
    },
  },
  spribeGames: {
    Mine: {
      href: "/casino/ezugi/Mine",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    Goal: {
      href: "/casino/ezugi/Goal",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },

    Dice: {
      href: "/casino/ezugi/Dice",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    Avitar: {
      href: "/casino/ezugi/Avitar",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    Pilnko: {
      href: "/casino/ezugi/Pilnko",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    Miniroulette: {
      href: "/casino/ezugi/Miniroulette",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    Hilo: {
      href: "/casino/ezugi/Hilo",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },
  popularGames: {
    lucky7: {
      href: "/casino/ezugi/lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/01.jpg", alt: "" },
    },
    Monopoly: {
      href: "/casino/evolution/Monopoly",
      code: "228001",
      casino: "ezugi",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/popular/02.jpg", alt: "" },
    },

    Dragontiger: {
      href: "/casino/ezugi/Dragontiger",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/03.jpg", alt: "" },
    },
    Speed: {
      href: "/casino/ezugi/Speed",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/04.jpg", alt: "" },
    },
    teenpatti3card: {
      href: "/casino/ezugi/teenpatti3card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/05.jpg", alt: "" },
    },
    // Miniroulette2: {
    //   href: "/casino/ezugi/Miniroulette2",
    //   code: "228001",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/popular/06.jpg", alt: "" },
    // },
    thirty2cards: {
      href: "/casino/ezugi/thirty2cards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/indian/04.jpg", alt: "" },
    },
  },
  slotsgames: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/01.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/02.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/03.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/04.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/05.jpg", alt: "" },
    },
  },
  Proivdergames: {
    Evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/01.png", alt: "" },
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/02.png", alt: "" },
    },

    supernova: {
      href: "/casino/supernowa",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/03.png", alt: "" },
    },
    xpg: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/04.png", alt: "" },
    },
  },
  PaymentMethod: {
    netbanking: {
      href: "/casino/Evolution",
      code: "",
      casino: "evolution",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/netbanking.svg", alt: "" },
    },
    upi: {
      href: "/casino/ezugi",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/upi.svg", alt: "" },
    },

    visa: {
      href: "/casino/supernova",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/visa.svg", alt: "" },
    },
    mastercard: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/mastercard_white.svg", alt: "" },
    },
    paytm: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/paytm.svg", alt: "" },
    },
    Phonepe: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/phonepe.svg", alt: "" },
    },
    gpay: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/gpay.svg", alt: "" },
    },
    airtel: {
      href: "/casino/xpg",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/payments/airtel.svg", alt: "" },
    },
  },

  spribeGames: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },

    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    pilnko: {
      href: "/casino/spribe/pilnko",
      code: "pilnko",
      casino: "spribe",
      provider: "pilnko",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    hilo: {
      href: "/casino/spribe/hi-lo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },

  teenpatti: {
    Teenpatti3card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/teen-patti/ThreeCardsTeenPatti.webp",
      alt: "",
      gameName: "Teen Patti 3 Card",
    },
    Betonteenpatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/teen-patti/BetOnTeenPatti-Ezg.webp",
      alt: "",
      gameName: "Bet on Teen Patti",
    },
    Onedayteenpatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/teen-patti/OneDayTeenpatti-Ezg.webp",
      alt: "",
      gameName: "One Day Teen Patti",
    },
  },

  andarbahar: {
    Andarbahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/andar-bahar/AndarBahar.webp",
      alt: "",
      gameName: "Andar Bahar",
    },
    CasinoMarinaAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/andar-bahar/AndarBaharCasinoMarina.webp",
      alt: "",
      gameName: "OTT Andar Bahar",
    },
    UltimateAndarbahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/andar-bahar/UltimateAndarBahar.webp",
      alt: "",
      gameName: "Ultimate Andar Bahar",
    },
  },

  roulette: {
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/UltimateRoulette-Ezg.webp",
      alt: "",
      gameName: "Ultimate Roulette",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/SpeedRoulette-Ezg.webp",
      alt: "",
      gameName: "Speed Roulette",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/NamasteRoulette-Ezg.webp",
      alt: "",
      gameName: "Namaste Roulette",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/AutoRoulette-Ezg.webp",
      alt: "",
      gameName: "Auto Roulette",
    },
    CricketAutoRoulette: {
      redirectUrl: "/casino/ezg-cricket-auto-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/CricketAutoRoulette-Ezg.webp",
      alt: "",
      gameName: "Cricket Auto Roulette",
    },
    DiamondRoulette: {
      redirectUrl: "/casino/ezg-diamond-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/DiamondRoulette-Ezg.webp",
      alt: "",
      gameName: "Diamond Roulette",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/SpeedRoulette-Ezg.webp",
      alt: "",
      gameName: "Speed Auto Roulette",
    },
    SkylineRoulette: {
      redirectUrl: "/casino/ezg-skyline-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/SkylineRoulette-Ezg.webp",
      alt: "",
      gameName: "Skyline Roulette",
    },
    PrestigeAutoRoulette: {
      redirectUrl: "/casino/ezg-prestige-auto-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/PrestigeAutoRoulette-Ezg.webp",
      alt: "",
      gameName: "Prestige Auto Roulette",
    },
    ItalianRoulette: {
      redirectUrl: "/casino/ezg-italian-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/ItalianRoulette-Ezg.webp",
      alt: "",
      gameName: "Italian Roulette",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/LightningRoulette-Evo.webp",
      alt: "",
      gameName: "Lightning Roulette",
    },
    SpeedAutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/AutoSpeedRoulette-Evo.webp",
      alt: "",
      gameName: "Speed Auto Roulette",
    },
    InstantRoulette: {
      redirectUrl: "/casino/ezgevo-instant-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/InstantRoulette-Evo.webp",
      alt: "",
      gameName: "Instant Roulette",
    },
    AmericanRoulette: {
      redirectUrl: "/casino/ezgevo-american-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/AmericanRoulette-Evo.webp",
      alt: "",
      gameName: "American Roulette",
    },
    ImmersiveRoulette: {
      redirectUrl: "/casino/ezgevo-immersive-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/ImmersiveRoulette-Evo.webp",
      alt: "",
      gameName: "Immersive Roulette",
    },
    VIPRoulette: {
      redirectUrl: "/casino/ezgevo-vip-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/VipRoulette-Evo.webp",
      alt: "",
      gameName: "VIP Roulette",
    },
    FootballStudioRoulette: {
      redirectUrl: "/casino/ezgevo-football-studio-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/FootballStudioRoulette-Evo.webp",
      alt: "",
      gameName: "Football Studio Roulette",
    },
    DoubleBallRoulette: {
      redirectUrl: "/casino/ezgevo-double-ball-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/DoubleballRoulette-Evo.webp",
      alt: "",
      gameName: "Double Ball Roulette",
    },
    SalonPriveRoulette: {
      redirectUrl: "/casino/ezgevo-salon-prive-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/salonpriveroulette-Evo.webp",
      alt: "",
      gameName: "Salon Privé Roulette",
    },
    DragonaraRoulette: {
      redirectUrl: "/casino/ezgevo-dragonara-roulette",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/roulette/Dragonara-Evo.webp",
      alt: "",
      gameName: "Dragonara Roulette",
    },
  },

  dragontiger: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/dragon-tiger/DragonTiger-Ezg.webp",
      alt: "",
      gameName: "Dragon Tiger",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/dragon-tiger/DragonTiger-Evo.webp",
      alt: "",
      gameName: "Dragon Tiger",
    },
    FirstPersonDragonTiger: {
      redirectUrl: "/casino/ezgevo-first-person-dragon-tiger",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl:
        "./images/games-image/dragon-tiger/DragonTigerFirstPerson-Evo.webp",
      alt: "",
      gameName: "First Person Dragon Tiger",
    },
  },

  baccarat: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/baccarat-Ezg.webp",
      alt: "",
      gameName: "Baccarat",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/FortuneBaccarat-Ezg.webp",
      alt: "",
      gameName: "Fortune Baccarat",
    },
    VIPFortuneBaccarat: {
      redirectUrl: "/casino/ezg-vip-fortune-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/VipFortuneBaccarat-ezg.webp",
      alt: "",
      gameName: "VIP Fortune Baccarat",
    },
    SpeedFortuneBaccarat: {
      redirectUrl: "/casino/ezg-speed-fortune-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/SpeedFortuneBaccarat-Ezg.webp",
      alt: "",
      gameName: "Speed Fortune Baccarat",
    },
    CasinoMarinaBaccarat1: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/CasinoMarinaBaccarat1.webp",
      alt: "",
      gameName: "Casino Marina Baccarat 1",
    },
    CasinoMarinaBaccarat2: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/Casinomarinabaccarat2.webp",
      alt: "",
      gameName: "Casino Marina Baccarat 2",
    },
    CasinoMarinaBaccarat3: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-3",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/CasinoBaccarat3.webp",
      alt: "",
      gameName: "Casino Marina Baccarat 3",
    },
    CasinoMarinaBaccarat4: {
      redirectUrl: "/casino/ezg-casino-marina-baccarat-4",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/CasinoMarinaBaccarat4-ezg.webp",
      alt: "",
      gameName: "Casino Marina Baccarat 4",
    },
    Super6Baccarat: {
      redirectUrl: "/casino/ezg-super-6-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/Super6Baccarat-ezg.webp",
      alt: "",
      gameName: "Super 6 Baccarat",
    },
    KnockoutBaccarat: {
      redirectUrl: "/casino/ezg-knockout-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/KnockoutBaccarat-Ezg.webp",
      alt: "",
      gameName: "Knockout Baccarat",
    },
    BaccaratB: {
      redirectUrl: "/casino/ezgevo-baccarat-b",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratB-Evo.webp",
      alt: "",
      gameName: "Baccarat B",
    },
    BaccaratA: {
      redirectUrl: "/casino/ezgevo-baccarat-a",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratA-Evo.webp",
      alt: "",
      gameName: "Baccarat A",
    },
    BaccaratControlSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-control-squeeze",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratControlSqueeze-Evo.webp",
      alt: "",
      gameName: "Baccarat Control Squeeze",
    },
    BaccaratSqueeze: {
      redirectUrl: "/casino/ezgevo-baccarat-squeeze",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratSqueeze-Evo.webp",
      alt: "",
      gameName: "Baccarat Squeeze",
    },
    FirstPersonBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratFirstPerson-Evo.webp",
      alt: "",
      gameName: "First Person Baccarat",
    },
    FirstPersonGoldenWealthBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-golden-wealth-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl:
        "./images/games-image/baccarat/GOldenWealthBaccaratFirstPerson-Evo.webp",
      alt: "",
      gameName: "First Person Golden Wealth Baccarat",
    },
    FirstPersonLightningBaccarat: {
      redirectUrl: "/casino/ezgevo-first-person-lightning-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl:
        "./images/games-image/baccarat/LightningBaccaratFirstPerson-Evo.webp",
      alt: "",
      gameName: "First Person Lightning Baccarat",
    },
    GoldenWealthBaccarat: {
      redirectUrl: "/casino/ezgevo-golden-wealth-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/GoldenWealthBaccarat-Evo.webp",
      alt: "",
      gameName: "Golden Wealth Baccarat",
    },
    LightningBaccarat: {
      redirectUrl: "/casino/ezgevo-lightning-baccarat",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/LightningBaccarat-Evo.webp",
      alt: "",
      gameName: "Lightning Baccarat",
    },
    NoCommissionBaccaratEVO: {
      redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/baccarat/BaccaratNoCommission-Evo.webp",
      alt: "",
      gameName: "No Commission Baccarat-EVO",
    },
  },

  blackjack: {
    GoldBlackjack5: {
      redirectUrl: "/casino/ezg-gold-blackjack-5",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/GoldBlackjack5-Ezg.webp",
      alt: "",
      gameName: "Gold Blackjack 5",
    },
    GoldBlackjack3: {
      redirectUrl: "/casino/ezg-gold-blackjack-3",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/GoldBlackjack3.webp",
      alt: "",
      gameName: "Gold Blackjack 3",
    },
    GoldBlackjack1: {
      redirectUrl: "/casino/ezg-gold-blackjack-1",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/GoldBlackjack1.webp",
      alt: "",
      gameName: "Gold Blackjack 1",
    },
    VIPSurrenderBlackjack: {
      redirectUrl: "/casino/ezg-vip-surrender-blackjack",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/VipSurrenderBlackjack-Ezg.webp",
      alt: "",
      gameName: "VIP Surrender Blackjack",
    },
    VIPDiamondBlackjack: {
      redirectUrl: "/casino/ezg-vip-diamond-blackjack",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/VipDiamondBlackjack-Ezg.webp",
      alt: "",
      gameName: "VIP Diamond Blackjack",
    },
    RumbaBlackjack1: {
      redirectUrl: "/casino/ezg-rumba-blackjack-1",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/RumbaBlackjack1.webp",
      alt: "",
      gameName: "Rumba Blackjack 1",
    },
    RumbaBlackjack4: {
      redirectUrl: "/casino/ezg-rumba-blackjack-4",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/RumbaBlackjack4.webp",
      alt: "",
      gameName: "Rumba Blackjack 4",
    },
    UnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-unlimited-blackjack",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/UltimateBlackjack-Ezg.webp",
      alt: "",
      gameName: "Unlimited Blackjack",
    },
    SpanishUnlimitedBlackjack: {
      redirectUrl: "/casino/ezg-spanish-unlimited-blackjack",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl:
        "./images/games-image/blackjack/SpanishUltimateBlackjack-Ezg.webp",
      alt: "",
      gameName: "Spanish Unlimited Blackjack",
    },
    BlackjackB: {
      redirectUrl: "/casino/ezgevo-blackjack-b",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/BlackjackB-Evo.webp",
      alt: "",
      gameName: "Blackjack B",
    },
    BlackjackA: {
      redirectUrl: "/casino/ezgevo-blackjack-a",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/BlackjackA-Evo.webp",
      alt: "",
      gameName: "Baccarat A",
    },
    BlackjackParty: {
      redirectUrl: "/casino/ezgevo-blackjack-party",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/BlackjackParty-Evo.webp",
      alt: "",
      gameName: "Blackjack Party",
    },
    BlackjackC: {
      redirectUrl: "/casino/ezgevo-blackjack-c",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/BlackjackC-Evo.webp",
      alt: "",
      gameName: "Blackjack C",
    },
    BlackjackSilverA: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-a",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/BlackjackSilverA.webp",
      alt: "",
      gameName: "Blackjack Silver A",
    },
    BlackjackSilverC: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-c",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/SilverBlackjackC-Evo.webp",
      alt: "",
      gameName: "Blackjack Silver C",
    },
    BlackjackSilverB: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-b",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/SilverBlackjackB-Evo.webp",
      alt: "",
      gameName: "Blackjack Silver B",
    },
    BlackjackSilverE: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-e",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/SilverBlackjackE-Evo.webp",
      alt: "",
      gameName: "Blackjack Silver E",
    },
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-d",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/SilverBlackjackD-Evo.webp",
      alt: "",
      gameName: "Blackjack Silver D",
    },
    BlackjackSilverG: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-g",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/SilverBlackjackG.webp",
      alt: "",
      gameName: "Blackjack Silver G",
    },
    BlackjackVIP1: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-1",
      code: "1000124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/games-image/blackjack/VipBlackjack-Evo.webp",
      alt: "",
      gameName: "Blackjack VIP 1",
    },
  },
};
