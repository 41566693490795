import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/icons/leftbar/Home.webp";
import CasinoIcon from "./../../assets/images/icons/leftbar/WorldCasino.webp";
import InplayIcon from "./../../assets/images/icons/leftbar/In-play.webp";
import CricketIcon from "./../../assets/images/icons/leftbar/Cricket.webp";
import SoccerIcon from "./../../assets/images/icons/leftbar/Soccer.webp";
import TennisIcon from "./../../assets/images/icons/leftbar/Tennis.webp";
import horseracingIcon from "./../../assets/images/icons/leftbar/Horse.webp";

const LeftBarSports = () => {
  const navigate = useNavigate();
  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li>
            <a
              className="active"
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sports");
              }}
            >
              <img src={HomeIcon} alt="Home Icon" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Inplay", { state: { selectedTab: "1" } });
              }}
            >
              <img src={InplayIcon} alt="Inplay Icon" />
              <span>In-play</span>
            </a>
          </li>
          {/* <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/casino/worldcasino");
              }}
            >
              <img src={CasinoIcon} alt="Casino Icon" />
              <span>World Casino</span>
            </a>
          </li> */}
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Cricket", {
                  state: { selectedTab: "2" },
                });
              }}
            >
              <img src={CricketIcon} alt="Cricket Icon" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Soccer", { state: { selectedTab: "3" } });
              }}
            >
              <img src={SoccerIcon} alt="Soccer Icon" />
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sportsbook/Tennis", { state: { selectedTab: "4" } });
              }}
            >
              <img src={TennisIcon} alt=" Icon" />
              <span>Tennis</span>
            </a>
          </li>
          <li>
            <a href="/sportsbook/Horse%20Racing">
              <img src={horseracingIcon} alt="horseracing Icon" />
              <span>Horse Racing</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
