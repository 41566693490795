import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BackIcon from "../../../../assets/svg/BackIcon";
import { Container } from "react-bootstrap";
import { casinoTabs } from "../../../../lib/data";

const DragonTiger = () => {
  return (
    <>
      <HeaderAfterLogin />

      <main className="main" id="profile_page">
        <Container>
          <div className="pagesHeading">
            <h2 className="sectionTitle border-0">Dragon Tiger</h2>
            <a className="back-link" href="/">
              Back <BackIcon />
            </a>
          </div>

          <div className="CasinoGameSection">
            {Object.values(casinoTabs?.dragontiger).map((item, id) => {
              return (
                <div key={id} className="gameBoxSection">
                  <div className="game_box">
                    <a href={item.redirectUrl}>
                      <img src={item.imgUrl} alt={item.alt} />
                      <p>{item.gameName}</p>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </main>

      <Footer />
    </>
  );
};

export default DragonTiger;
